import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const Digatus = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot digatus it group AG" />
      <StyledHero img={data.digatusBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="digatus it group AG" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://www.digatus.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/digatus.png"
                alt="digatus Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot digatus it group AG</h4>
          </header>
          <article className={styles.offerText}>
            <p>
              Der plötzliche Wechsel vieler Mitarbeiter zu 100% Home-Office
              stellt Unternehmen und ihre Mitarbeiter vor völlig neue
              Herausforderungen. Arbeitskultur, Kommunikation und Infrastruktur
              müssen aufgrund der Corona-Pandemie und der Maßnahmen der
              Regierung in vielen Unternehmen neu gedacht und gelebt werden.
            </p>
            <p>
              Die Situation zwingt viele Unternehmen, sich umgehend mit der
              Digitalisierung zu befassen. Die Gelegenheit kann genutzt werden,
              um Vertrauen in die neuen Technologien und Ideen zu schaffen und
              eine moderne Zusammenarbeit im Unternehmen zu fördern.
            </p>
            <p>
              Wir beraten IT-Manager bei der Umsetzung von Cloud-Strategien und
              unterstützen bei der schnellen, aber auch nachhaltigen Einführung
              von Microsoft Teams und legen dar, welche Chancen und Einflüsse
              dies auf eine vollständige Migration zu Microsoft 365 (ehem.
              Office 365) hat.
            </p>
            <p>
              Darüber hinaus helfen wir den Mitarbeitern mit pragmatischen
              Online-Tutorials, die Lösungen und Arbeitsweisen zu verstehen,
              damit sie diese effektiv nutzen können.
            </p>
          </article>
          <footer>
            <a
              href="mailto:kontakt@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    digatusBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Digatus
